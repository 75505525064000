import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Clients from "./Clients";
import { clientsData } from "../../data/clientsData";
import { detailsData } from "../../data/detailsData";
import Details from "./Details";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./homePage.css";
import Chatbot from "../chatbot/Chatbot";

const HomePage = () => {
  return (
    <>
      {/* <Chatbot /> */}
      <Stack>
        {/* VideoPlayer */}
        <VideoPlayer src="Home.mp4" controls={false} />
        {/* Banner */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
          }}
        >
          <img
            src="https://go.coguide.in/c1/download/Image1_1716869474570.jpg"
            alt="banner"
            style={{
              width: "80%",
              objectFit: "contain",
            }}
          />
        </Box>
        {/* Clients */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1rem", md: "2.125rem" },
              }}
            >
              Trusted By Our Clients
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "40px" },
              marginBottom: "-1rem",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {clientsData.map((client, index) => (
              <Clients key={index} imgUrl={client.imgUrl} />
            ))}
          </Box>
        </Box>
        {/* <Details/> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5rem",
            gap: "8rem",
          }}
        >
          {detailsData.map((detail, index) => (
            <Details detail={detail} key={index} />
          ))}
        </Box>
      </Stack>
    </>
  );
};

export default HomePage;
