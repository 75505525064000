import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { FaQuoteLeft, FaHome } from "react-icons/fa";
import "./Registries.css";
import registriesList from "./sampleRegistriesData";
import RegistryCard from "./RegistryCard";
import PartnerCarousel from "./PartnerCarousel";

const RegistryDetail = ({ data, onClose, onAction }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const updatedRegistries = ["Nephrotic syndrome","Infertility", "Uterine Leiomyoma","Breast cancer","Ovarian cancer","Renal Transplant","Total Knee Arthroplasty (TKA)","Pituitary adenoma","Very low birth weight","Non-Alcoholic Fatty Liver Disease (NAFLD)","Cardiac","Vesicoureteral Reflux (VUR) ","Aplastic anemia"];

  function isDataValid() {
    const requiredFields = [
      "status",
      "title_small",
      "title_big",
      "thumbnail",
      "objective_small",
      "objective_big",
      "type",
      "registry_mission",
      "registry_vision",
      "record_count",
      "partner_count",
    ];

    // Check if required fields are present and not empty
    for (const field of requiredFields) {
      if (
        !data[field] ||
        (typeof data[field] === "string" && data[field].trim() === "")
      ) {
        console.error(`Missing or empty field: ${field}`);
        return false;
      }
    }
    return true;
  }

  if (!isDataValid()) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "300px",
          padding: "2rem",
        }}
      >
        <Typography
          variant={isMobile ? "h3" : "h2"}
          color="#3383F0"
          gutterBottom
        >
          Coming Soon
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <Box
            sx={{
              height: isMobile ? "40px" : "50px",
              width: isMobile ? "40px" : "50px",
              bgcolor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: isMobile ? "1.5rem" : "2rem",
              color: "#006EDA",
              cursor: "pointer",
              zIndex: "1000",
              marginRight: "0.5rem",
            }}
          >
            <FaHome />
          </Box>
          <Typography variant={isMobile ? "h5" : "h4"}>
            Back To Registry Page
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", bgcolor: "#e2f1ffab", padding: "4rem 0" }}>
      <Stack spacing={4} sx={{ width: "85%", margin: "0 auto" }}>
        {/* Header Section */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{ minHeight: { xs: "auto", md: "200px" } }}
        >
          <Box
            sx={{
              flex: 2,
              bgcolor: "#3383F0",
              borderRadius: "30px",
              padding: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaQuoteLeft
              style={{
                fontSize: isMobile ? "4rem" : "4rem",
                color: "#85B5F6",
                marginRight: "1rem",
              }}
            />
            <Typography
              variant={isMobile ? "h6" : "h4"}
              color="white"
              sx={{
                fontWeight: "600",
              }}
            >
              {data.title_big}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              borderRadius: "30px",
              overflow: "hidden",
              height: { xs: "auto", md: "auto" },
            }}
          >
            <img
              src={data.thumbnail}
              alt="registry-image"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Stack>

        {/* What is Registry Section */}
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Box
            sx={{
              flex: 2,
              bgcolor: "white",
              borderRadius: "30px",
              padding: isMobile ? "2rem" : "4rem",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              color="#3383f0"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              What is {data.title_small} registry?
            </Typography>
            <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
              A {data.title_small} registry is an information system designed
              for the collection, storage, and management of data on persons
              with {data.title_small}.
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              bgcolor: "white",
              borderRadius: "30px",
              padding: isMobile ? "2rem" : "4rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                Type:
              </Typography>
              <Typography variant={isMobile ? "h6" : "h4"} color="#55575C">
                {data.type}
              </Typography>
            </Box>
            <hr style={{ width: "100%", color: "grey", opacity: "0.4" }} />
            <Box>
              <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                Status:
              </Typography>
              <Typography
                variant={isMobile ? "h6" : "h4"}
                color={
                  data.status === "Active"
                    ? "#50C878"
                    : data.status === "In Process"
                    ? "#E2862A"
                    : "#FF5733"
                }
              >
                {data.status}
              </Typography>
            </Box>
          </Box>
        </Stack>

        {/* Vision and Mission Section */}
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Stack spacing={2} sx={{ flex: 1 }}>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "30px",
                padding: isMobile ? "2rem" : "4rem",
                height: "35%",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                color="#3383f0"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Vision:
              </Typography>
              <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                {data.registry_vision}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "30px",
                padding: isMobile ? "2rem" : "4rem",
                height: "65%",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                color="#3383f0"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Mission:
              </Typography>
              <Typography
                variant={isMobile ? "body1" : "h5"}
                color="#5a5c61"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                }}
              >
                {data.registry_mission.split("\n").map((item, index) => (
                  <span key={index}>
                    {item}
                    <br />
                  </span>
                ))}
              </Typography>
            </Box>
          </Stack>
          <Stack
            spacing={2}
            sx={{ flex: 1.5, maxWidth: "600px", margin: "0 auto" }}
          >
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "30px",
                padding: isMobile ? "2rem" : "4rem",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                color="#3383f0"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Objective:
              </Typography>
              <Typography
                variant={isMobile ? "body1" : "h5"}
                color="#5a5c61"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {data.objective_big.split("\n").map(
                  (item, index) =>
                    item && (
                      <span key={index}>
                        {item.trim()}
                        <br />
                      </span>
                    )
                )}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                bgcolor: "#006EDA",
                borderRadius: "30px",
                padding: isMobile ? "1rem" : "2rem",
                color: "white",
                display: "flex",
              }}
            >
              <Stack
                spacing={2}
                justifyContent="space-around"
                sx={{
                  width: "50%",
                }}
              >
                <Box>
                  <Typography variant={isMobile ? "h6" : "h4"}>
                    Record count: {data.record_count}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant={isMobile ? "h6" : "h4"}>
                    Partner count: {data.partner_count}
                  </Typography>
                </Box>
              </Stack>
              <hr
                style={{
                  margin: "0",
                }}
              />
              <Box
                sx={{
                  marginTop: "1rem",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src="/images/blossom_hospital.jpeg"
                  alt={data.partners[0].name}
                  style={{
                    width: "100%",
                    maxHeight: "300px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant={isMobile ? "h6" : "h4"}
                  align="center"
                  sx={{ marginTop: "0.5rem" }}
                >
                  {data.partners[0].name}{" "}
                  <span
                    style={{
                      color:
                        data.partners[0].partner_type === "gold"
                          ? "#FFD700"
                          : "#FFD700",
                      fontSize: isMobile ? "0.8rem" : "1rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {data.partners[0].partner_type} Partner
                  </span>
                </Typography>
              </Box>
            </Box> */}
            <PartnerCarousel
              data={data}
              isMobile={isMobile}
              sx={{ maxWidth: "600px" }}
            />
          </Stack>
        </Stack>

        {/* Contact and Data Utilization Section */}
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Box
            sx={{
              flex: 1,
              bgcolor: "white",
              borderRadius: "30px",
              padding: isMobile ? "2rem" : "4rem",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              color="#5a5c61"
              gutterBottom
              sx={{ marginBottom: "2rem" }}
            >
              Message to Encourage Joining the Registry
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              spacing={2}
            >
              <Box>
                <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                  Contact Number:
                </Typography>
                <Typography variant={isMobile ? "body1" : "h6"}>
                  {data.supervisor_contact_details.contact_number ||
                    "+91 99 99 99 99 88"}
                </Typography>
              </Box>
              <Box>
                <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                  Email ID:
                </Typography>
                <Typography variant={isMobile ? "body1" : "h6"}>
                  {data.supervisor_contact_details.emailid ||
                    "sample@gmail.com"}
                </Typography>
              </Box>
              <Box>
                <Typography variant={isMobile ? "body1" : "h5"} color="#5a5c61">
                  Contact Person:
                </Typography>
                <Typography variant={isMobile ? "body1" : "h6"}>
                  {data.supervisor_contact_details.name || "Phani Chaitanya"}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              flex: 1,
              bgcolor: "white",
              borderRadius: "30px",
              padding: isMobile ? "2rem" : "4rem",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              color="#5a5c61"
              gutterBottom
              sx={{ marginBottom: "2rem" }}
            >
              Data Utilization Policy:
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              color="#5a5c61"
              paragraph
            >
              Data collected within the registry is intended for use in
              research, public health initiatives, and policy development.
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              color="#006EDA"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Click here to download a detailed doc of use.
            </Typography>
          </Box>
        </Stack>

        {/* Data Collection Procedures Section */}
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: "30px",
            padding: isMobile ? "2rem" : "4rem",
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            color="#3383f0"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Data Collection Procedures
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h5"}
            color="#5a5c61"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {data.procedures_doc.split("\n").map(
              (item, index) =>
                item && (
                  <span key={index}>
                    {item.trim()}
                    <br />
                  </span>
                )
            )}
          </Typography>
        </Box>

        {/* Action Buttons Section */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor: "white",
            borderRadius: "30px",
            padding: isMobile ? "2rem" : "4rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => window.open(data.partner_registration_link)}
            sx={{
              minWidth: isMobile ? "150px" : "300px",
              fontSize: isMobile ? "1rem" : "1.8rem",
            }}
          >
            Register to Join
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(data.usage_request_registration_link)}
            sx={{
              minWidth: isMobile ? "150px" : "300px",
              fontSize: isMobile ? "1rem" : "1.8rem",
            }}
          >
            Research Application
          </Button>
        </Stack>

        {/* Back to Registry Page Button */}
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              cursor: "pointer",
              fontSize: isMobile ? "1.2rem" : "2rem",
              fontWeight: "bold",
              color: "#3383F0",
              textDecoration: "underline",
            }}
            onClick={onClose}
          >
            Back To Registry Page
          </Typography>
        </Box>
      </Stack>

      {/* Floating Home Button */}
      <Box
        sx={{
          position: "fixed",
          right: "2rem",
          bottom: "2rem",
          height: "60px",
          width: "60px",
          bgcolor: "white",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
          color: "#006EDA",
          cursor: "pointer",
          zIndex: "1000",
          boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
        }}
        onClick={onClose}
      >
        <FaHome />
      </Box>
    </Box>
  );
};

export default RegistryDetail;
