import React from "react";
import { Link } from "react-router-dom";

{
  /* <Link to={`/job/${job.id}`}>
        <h3>{job.title}</h3>
        <p>{job.company}</p>
        <p>{job.location}</p>
      </Link> */
}
const JobCard = ({ job, onClick }) => {
  return (
    <div className="job-card" onClick={() => onClick(job)}>
      <div className="job-card-upper">
        <p>{job?.title}</p>
      </div>
      <div className="job-card-lower">
        <div className="logo">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="coguide logo"
            height="40px"
          />
        </div>
        <div className="job-title">
          <p>{job?.title}</p>
        </div>
        <div className="view-button">
          <button>View</button>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
