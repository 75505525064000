import Box from "@mui/material/Box";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Careers.css";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import JobCard from "./JobCard";
import JobDetail from "./JobDetail";
import "./Careers.css";
import { getJobDescriptions } from "../../api/apiController";

const Careers = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        let response = await getJobDescriptions();
        setJobs(response.data);
        setFilteredJobs(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (jobs.length === 0) {
      fetchJobs();
    }
  }, []);

  useEffect(() => {
    const filtered =
      jobs &&
      jobs.filter((job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setFilteredJobs(filtered);
  }, [searchTerm, jobs]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onAction = (value, data = {}) => {
    switch (value) {
      case "jobdetail": {
        setSelectedJob(data);
        setAction(value);
        break;
      }
      default: {
        setAction("");
      }
    }
  };

  return (
    <>
      {action === "jobdetail" && (
        <JobDetail job={selectedJob} onClose={() => setAction("")} />
      )}
      {action === "" && (
        <Stack className="careers-page">
          <div className="careers-header-container">
            <Box
              sx={{
                padding: "20px 30px",
                fontSize: { xs: "2rem", md: "3rem" },
                height: "100%",
              }}
            >
              Become Part Of Our Team
            </Box>
            <div className="roundIcon">
              {Array(4)
                .fill(true)
                .map((item, index) => (
                  <FiberManualRecordRoundedIcon
                    key={index}
                    sx={{
                      color: "#005AD2",
                      fontSize: { xs: 15, md: 20 },
                    }}
                  />
                ))}
            </div>
          </div>
          <Box
            sx={{
              margin: "0 auto",
            }}
          >
            <TextField
              label="Search jobs"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ margin: "20px", width: "300px" }}
              size="small"
            />
          </Box>
          <div className="jobs-list">
            {filteredJobs &&
              filteredJobs.map((job) => (
                <JobCard
                  key={job.id}
                  job={job}
                  onClick={() => onAction("jobdetail", job)}
                />
              ))}
          </div>
          <Box
            sx={{
              padding: "20px 30px",
              fontSize: { xs: "1.5rem", md: "2rem" },
              height: "100%",
              textAlign: "center",
            }}
          >
            Send Your Resume To HR
            <span className="hr-email"> career@coguide.in</span>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default Careers;
