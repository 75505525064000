import { useLocation, useNavigate } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
import { useEffect, useState } from "react";
import "./SearchResponsePage.css";
import SearchResponsePageSearchBar from "./SearchResponsePageSearchBar";
import { AiOutlineClose } from "react-icons/ai";
import { getChatResponse } from "../../api/apiController";

const SearchResponsePage = () => {
  const navigate = useNavigate();
  const {
    searchInput,
    setSearchInput,
    chatMessages,
    setChatMessages,
    clearChatMessages,
  } = useSearch();
  const [response, setResponse] = useState("");
  const location = useLocation();
  const { query } = location.state || "";

  const handleClose = () => {
    clearChatMessages(); // Clear chat history on close
    navigate(-1);
  };

  const handlePromptSubmit = async () => {
    if (!searchInput.trim()) return;

    try {
      // Show a temporary loading message or state
      const loadingMessage = { query: searchInput, answer: "Loading..." };
      setChatMessages([...chatMessages, loadingMessage]);

      // Call the API to get the response
      const newResponse = await getChatResponse(searchInput);

      // Update the last chat message with the actual response
      setChatMessages((prevMessages) =>
        prevMessages.map((msg, index) =>
          index === prevMessages.length - 1
            ? { ...msg, answer: newResponse }
            : msg
        )
      );
    } catch (error) {
      console.error("Error fetching response:", error);
      const errorMessage = "An error occurred. Please try again.";
      setChatMessages((prevMessages) =>
        prevMessages.map((msg, index) =>
          index === prevMessages.length - 1
            ? { ...msg, answer: errorMessage }
            : msg
        )
      );
    } finally {
      // Clear the input for the next prompt
      setSearchInput("");
    }
  };

  useEffect(() => {
    handlePromptSubmit();
  }, []);

  return (
    <div className="chat-page">
      {/* Fixed Header */}
      <header className="chat-header">
        <div className="header-content">
          <h1>Ask anything about coGuide</h1>
          <button
            onClick={handleClose}
            className="close-button"
            aria-label="Close"
          >
            Close <AiOutlineClose />
          </button>
        </div>
      </header>

      {/* Chat Messages */}
      <div className="messages-container">
        <div className="messages-content">
          {chatMessages.map((msg, index) => (
            <div key={index} className="message-group">
              {/* User Message */}
              <div className="message user-message">
                <div className="avatar user-avatar" />
                <div className="message-bubble user-bubble">{msg.query}</div>
              </div>

              {/* Assistant Message */}
              <div className="message assistant-message">
                <div className="avatar assistant-avatar" />
                <div className="message-bubble assistant-bubble">
                  {msg.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed Search Bar */}
      <SearchResponsePageSearchBar
        handlePromptSubmit={handlePromptSubmit}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </div>
  );
};

export default SearchResponsePage;
